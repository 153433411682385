import React from 'react';
import PropTypes from 'prop-types';
import styles from './loading.module.scss';

const Loading = ({ isFluid }) => (
  <div className={`${styles.wrapper} ${isFluid ? styles.fluid : ''}`}>
    <div className={isFluid ? styles.spinner : styles.loader}></div>
  </div>
);

Loading.propTypes = {
  isFluid: PropTypes.bool,
};

export default Loading;
