import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { RiLogoutBoxRLine as LogoutIcon } from '@react-icons/all-files/ri/RiLogoutBoxRLine';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { getUserInitials, formatUserName } from 'utils/strings';
import useStore from 'store';
import routes from 'routes/routeList';
import styles from '../navbar.module.scss';

const ProfilePreview = ({ isOpen, setIsOpen }) => {
  const { user } = useStore((state) => state);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Perfil"
      overlayClassName={styles.profilePreviewOverlay}
      className={styles.profilePreviewModal}
    >
      <div className={styles.profilePreview}>
        <div className={styles.previewHeader}>
          <CloseIcon className={styles.closeIcon} onClick={() => setIsOpen(false)} />
        </div>
        <div className={styles.previewBody}>
          <div className={styles.userInfo}>
            <div className={styles.userInitials}>
              <span>{getUserInitials(user)}</span>
            </div>
            <div>
              <p className={styles.userName}>{formatUserName(user)}</p>
              <p className={styles.userEmail}>{user?.email}</p>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Link to={routes.profile} onClick={() => setIsOpen(false)}>
              <button className={styles.viewProfileButton}>Ver perfil</button>
            </Link>
            <Link
              className={styles.logoutButton}
              to={routes.logout}
              onClick={() => setIsOpen(false)}
            >
              <LogoutIcon />
              <span>Salir</span>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ProfilePreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ProfilePreview;
