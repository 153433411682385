import React from 'react';
import PropTypes from 'prop-types';
// import UnhandledError from 'components/UnhandledError';
import NetworkError from 'components/NetworkError';
import useStore from 'store';
// import config from 'config';

const ErrorBoundary = ({ children }) => {
  const { networkError } = useStore();

  return (
    <>
      {networkError ? <NetworkError /> : children}
    </>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
