import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CTA from 'components/CallToAction';
import InputPassword from 'components/Inputs/InputPassword';
import styles from './password-change.module.scss';
import { passwordChange } from 'api/onboarding';
import { toast } from 'react-hot-toast';
import onboardingStyles from '../../onboarding.module.scss';
import useForm from 'hooks/useForm';
import config from 'config';
import useIsMounted from 'hooks/useIsMounted';
const PasswordChange = ({ callback = () => { } }) => {
  const isMounted = useIsMounted();
  const inputRef = useRef();
  const { formData, handleInputChange, isDisabled } = useForm({
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      await passwordChange(formData);
      toast.success('¡Éxito!');
      callback();
    } catch (error) {
      setError(error.response?.data);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <>
      <form className={styles.form}>
        {error != '' && <div className={onboardingStyles.alertError}>{error}</div>}
        <h4 style={{ marginBottom: '0px' }}>Por tu seguridad ingresa una contraseña</h4>
        <input hidden type="text" autoComplete="email" />
        <div className={styles.inputContainer}>
          <InputPassword
            inputRef={inputRef}
            value={formData.password}
            onChange={handleInputChange}
            name="password"
            label="Contraseña"
            isNew={true}
          />
          <span
            className={onboardingStyles.caption}
          >{`Mínimo ${config.minPasswordLength} caracteres`}</span>
        </div>
        <CTA
          text="Crear contraseña"
          size="large"
          isLoading={loading}
          disabled={isDisabled}
          onClick={handleSubmit}
        />
      </form>
    </>
  );
};

PasswordChange.propTypes = {
  callback: PropTypes.func,
};

export default PasswordChange;
