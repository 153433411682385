import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Logo from 'components/Logo';
import styles from './onboarding.module.scss';
import { findNextSteps, startDemographic, getTokenBiometrics } from '../../api/onboarding';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import Loading from 'components/Loading';
import Demographic from './components/Demographic';
import FinishedProcess from './components/FinishedProcess';
import PasswordChange from './components/PasswordChange';
import useIsMounted from 'hooks/useIsMounted';
import { getUser } from 'api/user';
import Biometrics from './components/Biometrics';
import BiometricsFingerprint from './components/BiometricsFingerprint';
const OnboardingModal = ({ isOpen, setIsOpen, callback = () => { } }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [serviceCode, setServiceCode] = useState('');
  const [demographicData, setDemographicData] = useState([]);
  const [biometricsData, setBiometricsData] = useState({
    token: '',
    identityNumber: '',
    fingerprint: '',
    minMatch: 98
  });

  const [identityNumber, setIdentityNumber] = useState('');
  const isMounted = useIsMounted();

  const get = async () => {
    setLoading(true);
    try {
      setError('');
      const userData = await getUser();
      if (userData.passwordChangeRequired) {
        if (isMounted.current) setServiceCode('password-change');
      } else {
        const nextSteps = await findNextSteps();
        if (nextSteps.apiWebNextStep === 'biometrics' && nextSteps.customer.fingerprint) {
          const token = await getTokenBiometrics()
          setBiometricsData({
            token,
            fingerprint: nextSteps.customer.fingerprint,
            identityNumber: nextSteps.customer.identityNumber,
            minMatch: nextSteps.parameters.minMatch
          })
          await new Promise(resolve => setTimeout(resolve, 1000));
          setServiceCode(nextSteps.apiWebNextStep);
        } else if (nextSteps.apiWebNextStep === 'biometrics' && !nextSteps.customer.fingerprint) {
          setIdentityNumber(nextSteps.customer.identityNumber)
          await new Promise(resolve => setTimeout(resolve, 500));
          if (isMounted.current) setServiceCode(`${nextSteps.apiWebNextStep}-fingerprint`);
        } else {
          if (isMounted.current) setServiceCode(nextSteps.apiWebNextStep);
          if (nextSteps.apiWebNextStep === 'demographic') {
            const startDemographicsResponse = await startDemographic();
            setDemographicData(startDemographicsResponse);
          }
        }
      }
    } catch (error) {
      setError(error.response?.data);
      if (isMounted.current) setServiceCode('error');
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  const page = {
    demographic: <Demographic companies={demographicData} callback={get}></Demographic>,
    'finished-process-web': <FinishedProcess></FinishedProcess>,
    'password-change': <PasswordChange setIsOpen={setIsOpen} callback={get} />,
    error: <div className={styles.alertError}>{error}</div>,
    'biometrics': <Biometrics callback={get} biometricsData={biometricsData}></Biometrics>,
    'biometrics-fingerprint': <BiometricsFingerprint callback={get} identityNumber={identityNumber}></BiometricsFingerprint>,

  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
        onAfterOpen={get}
        // onAfterOpen={setServiceCode('')}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            {/* <p className={styles.title}>Misú</p> */}
            <Logo size="large"></Logo>
          </div>
          <div className={styles.content}>
            {loading ? <Loading isFluid={true} /> : page[serviceCode]}
          </div>
        </div>
      </Modal>
    </>
  );
};

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

export default OnboardingModal;
