import React, { Suspense } from 'react';
import Routes from './routes';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import SetInterceptors from 'components/SetInterceptors';
import ErrorBoundary from 'components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/Loading';

const App = () => (
  <div id="app-wrapper">
    <ErrorBoundary>
      <BrowserRouter>
        <SetInterceptors />
        <Suspense fallback={<Loading />}>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </Suspense>
        <Toaster />
      </BrowserRouter>
    </ErrorBoundary>
  </div>
);

export default App;
