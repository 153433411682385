import React from 'react';
import Logo from '../Logo';
import config from 'config';
import styles from './notFound.module.scss';
import sorryPng from 'assets/decorative-images/Sorry.png';

const NotFound = () => (
  <div className={styles.wrapper}>
    <div className={styles.logoWrapper}>
      <Logo size="large" />
    </div>
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={sorryPng}
          alt="Error"
          className={styles.image}
        />
        <div>
          <h2 className={styles.title}>Esta página no existe</h2>
          <p className={styles.message}>
            Lo sentimos, pero no podemos encontrar la página que buscas.
          </p>
          <button
            className={styles.button}
            onClick={() => window.location.replace(config.landingPageUrl)}
          >
            Ir a página principal
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
