import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

import Loading from 'components/Loading';
import useStore from 'store';
import useIsMounted from 'hooks/useIsMounted';

import { getAllLoans } from 'api/user';
import { startCase } from 'utils/strings';
import { toDollars } from 'utils/currency';
import { toCompactDate } from 'utils/dates';
import { formatBankAccount, toRGBA } from '../../utils/helpers';
import statusColors from '../../utils/statusColors';
import styles from './loan-list.module.scss';

const ListFooter = ({ count, totalCount, totalPages, page, handlePageChange }) => {
  if (count === 0) return null;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <span className={styles.footerText}>
          {totalCount} {totalCount === 1 ? 'anticipo' : 'anticipos'}
        </span>
        <div className={styles.footerButtonContainer}>
          {page > 1 && (
            <button
              className={`${styles.footerButton} ${styles.footerButtonPrevious}`}
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Anterior
            </button>
          )}
          <button
            className={styles.footerButton}
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

const ListBody = ({ loans, setIsFormOpen, handleClick }) => {
  if (loans.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan="100%" className={styles.emptyStateRow}>
            <div className={styles.emptyStateContent}>
              <span className={styles.emptyStateTitle}>Aún no tienes anticipos</span>
              <span className={styles.emptyStateCta} onClick={() => setIsFormOpen(true)}>
                Solicitar anticipo
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {loans.map((loan) => (
        <tr key={loan.id} className={styles.row} onClick={() => handleClick(loan)}>
          <td>{toCompactDate(loan.requestDate)}</td>
          <td className={styles.hideOnMobile}>
            {toCompactDate(loan.expectedRepaymentDate)}
          </td>
          <td className={styles.hideOnMobile}>{formatBankAccount(loan.bankAccount)}</td>
          <td className={styles.status}>
            <span
              style={{
                background: toRGBA(statusColors[loan.status], 0.1),
                color: statusColors[loan.status],
              }}
            >
              {loan.status == 'incobrable' ? startCase('vencido') : startCase(loan.status)}
            </span>
          </td>
          <td className={styles.amountField}>{toDollars(loan.requestedAmount)}</td>
          <td className={`${styles.hideOnMobile} ${styles.amountField}`}>
            {toDollars(loan.repaymentAmount)}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const LoanList = ({ setLoanId, setIsModalOpen }) => {
  const isMounted = useIsMounted();
  const tableRef = useRef();

  const { setIsFormOpen } = useStore((state) => state);
  const [loans, setLoans] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const limit = 5;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(async () => {
    const start = Date.now();
    const minMS = 500;

    try {
      const res = await getAllLoans(page, limit);

      if (isMounted.current) {
        setLoans(res.results);
        setTotalPages(res.totalPages);
        setTotalCount(res.totalCount);
      }

      const end = Date.now();
      const time = end - start;
      const remaining = minMS - time;

      if (remaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, remaining));
      }
    } catch (error) {
      if (isMounted.current) toast.error(error.response?.data);
    } finally {
      if (isMounted.current) setIsInitialLoading(false);
    }
  }, []);

  const handlePageChange = async (newPage) => {
    setIsNewPageLoading(true);
    scrollToTop();

    const start = Date.now();
    const minMS = 500;

    try {
      const res = await getAllLoans(newPage, limit);

      const end = Date.now();
      const time = end - start;
      const remaining = minMS - time;

      if (remaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, remaining));
      }

      if (isMounted.current) {
        setLoans(res.results);
        setPage(newPage);
      }
    } catch (error) {
      if (isMounted.current) toast.error(error.response?.data);
    } finally {
      if (isMounted.current) setIsNewPageLoading(false);
    }
  };

  const handleClick = (loan) => {
    setLoanId(loan.id);
    setIsModalOpen(true);
  };

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (isInitialLoading) {
    return (
      <div className={styles.initialLoadingContainer}>
        <Loading isFluid={true} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div ref={tableRef} className={styles.tableWrapper}>
          {isNewPageLoading && (
            <div className={styles.newPageLoadingContainer}>
              <Loading isFluid={true} />
            </div>
          )}
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Fecha solicitud</th>
                  <th className={styles.hideOnMobile}>Fecha vencimiento</th>
                  <th className={styles.hideOnMobile}>Cuenta</th>
                  <th className={styles.statusField}>Estatus</th>
                  <th className={styles.amountField}>Anticipo</th>
                  <th className={`${styles.hideOnMobile} ${styles.amountField}`}>
                    Retención
                  </th>
                </tr>
              </thead>
              <ListBody
                loans={loans}
                setIsFormOpen={setIsFormOpen}
                handleClick={handleClick}
              />
            </table>
          </div>
          <ListFooter
            totalCount={totalCount}
            totalPages={totalPages}
            count={loans.length}
            page={page}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

LoanList.propTypes = {
  setLoanId: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

ListBody.propTypes = {
  loans: PropTypes.array.isRequired,
  setIsFormOpen: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ListFooter.propTypes = {
  count: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default LoanList;
