import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { toast } from 'react-hot-toast';

import Select from 'components/SearchSelect';
import Input from 'components/Inputs/InputFloatingLabel';
import CTA from 'components/CallToAction';

import useIsMounted from 'hooks/useIsMounted';
import { addBankAccount } from 'api/bankAccount';
import { getAllBanks } from 'api/bank';
import { startCase } from 'utils/strings';
import styles from './account.module.scss';

const AddBankAccountContent = ({ setIsOpen, callback = () => {} }) => {
  const isMounted = useIsMounted();
  const [bankOptions, setBankOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState({
    bank: null,
    type: null,
    number: '',
    isDefault: true,
  });

  const { bank, number, type, isDefault } = account;

  const accountTypeOptions = [
    { value: 'ahorros', label: 'Cuenta de ahorros' },
    { value: 'corriente', label: 'Cuenta corriente' },
  ];

  useEffect(async () => {
    try {
      const data = await getAllBanks();
      const options = data.map((bank) => ({
        value: bank.id,
        label: startCase(bank.name),
      }));

      if (isMounted.current) setBankOptions(options);
    } catch (error) {
      if (isMounted.current) toast.error('Error al cargar lista de bancos');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  const handleBankSelect = (option) => {
    setAccount({ ...account, bank: option });
  };

  const handleTypeSelect = (option) => {
    setAccount({ ...account, type: option });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);

    const start = Date.now();
    const minLoadingTime = 1000;

    try {
      const formatted = { bankId: bank.value, type: type.value, number, isDefault };
      const data = await addBankAccount(formatted);

      const end = Date.now();
      const time = end - start;
      const remainingTime = minLoadingTime - time;
      if (remainingTime > 0) {
        await new Promise((resolve) => setTimeout(resolve, remainingTime));
      }

      toast.success('Cuenta bancaria añadida');
      callback(data);

      if (isMounted.current) {
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (error) {
      if (isMounted.current) {
        setIsLoading(false);
        setError(error.response?.data);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CloseIcon className={styles.closeIcon} onClick={() => setIsOpen(false)} />
        <p className={styles.title}>Nueva cuenta</p>
      </div>
      <div className={styles.content}>
        <form id="newBankAccountForm" className={styles.form} onSubmit={handleSubmit}>
          <Select
            label="Banco"
            defaultOption={bank}
            options={bankOptions}
            charsToOpenOptions={0}
            handleSelect={handleBankSelect}
          />
          <Select
            label="Tipo de cuenta"
            defaultOption={type}
            options={accountTypeOptions}
            charsToOpenOptions={0}
            handleSelect={handleTypeSelect}
          />
          <Input
            label="Número de cuenta"
            name="number"
            value={number}
            onChange={(event) => handleChange(event)}
            type="text"
            inputMode="numeric"
            autoComplete="off"
            pattern="[0-9]*"
          />
        </form>
        <div>
          <p className={`${styles.error} ${error ? styles.visible : ''}`}>
            {error || '.'}
          </p>
          <CTA
            text="Añadir cuenta"
            size="large"
            form="newBankAccountForm"
            isLoading={isLoading}
            onClick={handleSubmit}
            disabled={!number || !bank || !type}
          />
        </div>
      </div>
    </div>
  );
};

const AddBankAccountModal = ({ isOpen, setIsOpen, callback = () => {} }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={() => setIsOpen(false)}
    className={styles.modal}
    overlayClassName={styles.overlay}
    contentLabel="Añadir cuenta bancaria"
  >
    <AddBankAccountContent callback={callback} setIsOpen={setIsOpen} />
  </Modal>
);

AddBankAccountContent.propTypes = {
  callback: PropTypes.func,
  setIsOpen: PropTypes.func.isRequired,
};

AddBankAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

export default AddBankAccountModal;
