export const startCase = (string) => {
  const trimmed = string.trim();
  // Split by empty space or underscore
  const split = trimmed.split(/[\s_]/);

  const capitalized = [];

  split.forEach((word) => {
    capitalized.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
  });

  return capitalized.join(' ');
};

export const getQueryParams = (location) => {
  const searchString = location.search.substring(1);
  const decoded = decodeURIComponent(searchString);

  const urlParams = new URLSearchParams(decoded);
  const params = Object.fromEntries(urlParams.entries()); // JS Object

  return params;
};

export const getLast4 = (accountNumber) => {
  const last4 = accountNumber.slice(-4);
  return last4;
};

export const getUserInitials = (user) => {
  if (!user) return '';

  const { firstName, lastName } = user;
  const initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
  return initials;
};

export const formatUserName = (user) => {
  if (!user) return '';
  const { firstName, lastName } = user;

  const first = firstName.split(' ')[0];
  const last = lastName.split(' ')[0];
  return `${first} ${last}`;
};
