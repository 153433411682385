import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styles from './terms-not-accepted.module.scss';
import bookPng from 'assets/decorative-images/Book.png';

const TermsNotAcceptedContent = ({ setIsOpen, callback = () => { } }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img
          src={bookPng}
          alt="success high-five"
          className={styles.image}
        />
        <h2 className={styles.title}>Estimado/a cliente, </h2>
        <p className={styles.message}>
          Le informamos que para hacer uso de nuestra plataforma es necesario aceptar los
          &nbsp;
          <a
            style={{ color: 'blue', textDecoration: 'underline' }}
            onClick={() => { setIsOpen(false); callback() }}
          >
            <strong>términos y condiciones</strong>
          </a>
          . Sin dicha aceptación, no será posible acceder a nuestros servicios.
          Agradecemos su comprensión.
        </p>
        <button className={styles.button} onClick={() => setIsOpen(false)}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

const TermsNotAccepted = ({ isOpen, setIsOpen, callback = () => { } }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <TermsNotAcceptedContent setIsOpen={setIsOpen} callback={callback} />
      </Modal>
    </>
  );
};

TermsNotAccepted.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};
TermsNotAcceptedContent.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

export default TermsNotAccepted;
