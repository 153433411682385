import axios from '../lib/axios';

export const getUser = () => {
  return axios.get('/me');
};

export const updateUser = (data) => {
  return axios.patch('/me', data);
};

export const updatePassword = (data) => {
  return axios.put('/me/password', data);
};

export const getUserBankAccounts = () => {
  return axios.get('/me/bank-accounts?excludeRejected=true');
};

export const getAllLoans = (page, limit) => {
  return axios.get(`/me/loans?page=${page}&limit=${limit}`);
};

export const getOneLoan = (loanId) => {
  return axios.get(`/me/loans/${loanId}`);
};

export const getLoanDefaults = () => {
  return axios.get('/me/loan-defaults');
};
