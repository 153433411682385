import React, { useState, useEffect } from 'react';
import config from 'config';
import useStore from 'store';

import Navbar from 'components/Navbar';
import NoAccess from './components/NoAccess';
import Summary from './components/Summary';
import LoanList from './components/LoanList';
import LoanModal from './components/LoanModal';
import TermsModal from 'components/Terms';
import useTerms from 'hooks/useTerms';
import TyCNoAccess from './components/TyCNoAccess';
import PendingOnboarding from './components/PendingOnboarding';

import ShowMessage from './components/ShowMessage';
import OnboardingModal from 'components/Onboarding';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [tyCModalOpen, setTyCModalOpen] = useState(false);
  const { existsTermsConditionsToAccept } = useTerms();

  const [onboardingOpen, setOnboardingOpen] = useState(false);

  useEffect(() => {
    if (user.isPendingRegistration) setOnboardingOpen(true);

    existsTermsConditionsToAccept((requiresAcceptanceTerms) => {
      if (requiresAcceptanceTerms) setTyCModalOpen(true);
      (error) => toast.error(error);
    });
  }, []);

  const user = useStore((state) => state.user);
  const { approved, pending } = config.userStatuses;
  
  if (user.isPendingRegistration && !user.allowRegistration.next)
    return (
      <>
        <Navbar />
        <ShowMessage title={user.allowRegistration.title} message={user.allowRegistration.message} />
      </>
    );

  if (user.isPendingRegistration && !user.validateSelectedCompany.next)
    return (
      <>
        <Navbar />
        <ShowMessage title={user.validateSelectedCompany.option.title} message={user.validateSelectedCompany.option.message} />
      </>
    );

  if (user.isPendingRegistration)
    return (
      <>
        <Navbar />
        <PendingOnboarding />
        <OnboardingModal isOpen={onboardingOpen} setIsOpen={setOnboardingOpen} />
      </>
    );

  if (user.approvalStatus === pending && user.needsAcceptTermsNewUser)
    return (
      <>
        <Navbar />
        <TyCNoAccess />
      </>
    );

  if (user.approvalStatus !== approved)
    return (
      <>
        <Navbar />
        <NoAccess />
      </>
    );

  return (
    <>
      <Navbar />
      <Summary />
      <LoanList setLoanId={setLoanId} setIsModalOpen={setIsModalOpen} />
      <LoanModal loanId={loanId} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <TermsModal type={'TYC'} isOpen={tyCModalOpen} setIsOpen={setTyCModalOpen} />
    </>
  );
};

export default Home;
