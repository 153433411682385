import { useState, useEffect } from 'react';

const useForm = (initialData) => {
  const [formData, setFormData] = useState(initialData);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addFormData = (key, data) => {
    setFormData((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  const validateForm = () => {
    for (let key in formData) {
      if (!formData[key]) return setIsDisabled(true);
    }

    setIsDisabled(false);
  };

  useEffect(validateForm, [formData]);

  return {
    formData,
    setFormData,
    addFormData,
    handleInputChange,
    isDisabled,
  };
};

export default useForm;
