import React, { useState } from 'react';
import styles from './pending-incorporation.module.scss';
import OnboardingModal from 'components/Onboarding';
import bookPng from 'assets/decorative-images/Book.png';
// import CTA from 'components/CallToAction';
const PendingOnboarding = () => {
  const [onboardingOpen, setOnboardingOpen] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <p className={styles.subtitle}>
            ¡Muy pronto podrás disfrutar al máximo de nuestros servicios una vez que
            completes el proceso de registro! Estamos emocionados de que seas parte de
            Misú y de brindarte una experiencia excepcional.
            <br />
            <br />
            {/* <a
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={() => setOnboardingOpen(true)}
            >
              <strong>Continuar con el onboarding</strong>
            </a>
            <CTA
              text="Continuar con el onboarding"
              size="small"
              form="PendingOnboarding"
              onClick={() => setOnboardingOpen(true)}
            /> */}
            <button
              className={styles.button}
              onClick={() => {
                setOnboardingOpen(true);
              }}
            >
              Continuar con el registro
            </button>
          </p>
          <img
            className={styles.image}
            src={bookPng}
            alt=""
          />
        </div>
      </div>
      <OnboardingModal isOpen={onboardingOpen} setIsOpen={setOnboardingOpen} />
    </>
  );
};

export default PendingOnboarding;
