import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import AddBankAccount from 'components/AddBankAccount';
import CTA from 'components/CallToAction';
import { startCase, getLast4 } from 'utils/strings';
import useIsMounted from 'hooks/useIsMounted';
import styles from './choose-account.module.scss';
import BankLogo from '../../../../../components/BankLogo';

const ChooseBankAccount = ({
  bankAccounts,
  bankAccount,
  handleAccountChange,
  setBankAccount,
  setBankAccounts,
  setScreen,
}) => {
  const isMounted = useIsMounted();
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  const handleClick = async (account) => {
    const currentBankId = bankAccount.bank.id;
    const newBankId = account.bank.id;

    setBankAccount(account);
    handleAccountChange(currentBankId, newBankId);
    setTimeout(() => setScreen('main'), 300);
  };

  const handleNewAccountSuccess = (account) => {
    const newAccountList = [account, ...bankAccounts];
    const currentBankId = bankAccount.bank.id;
    const newBankId = account.bank.id;

    if (isMounted.current) {
      setBankAccounts(newAccountList);
      setBankAccount(account);
    }

    handleAccountChange(currentBankId, newBankId);
    setScreen('main');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Cuenta bancaria</span>
      </div>
      <div className={styles.accountListContainer}>
        <div className={styles.accountsList}>
          {bankAccounts.map((account) => (
            <div
              key={account.id}
              onClick={() => handleClick(account)}
              className={`${styles.account} ${bankAccount.id === account.id ? styles.active : ''
                }`}
            >
              <div className={styles.bankLogoContainer}>
                {/* <img src={account.bank.logoUrl} alt={account.bank.name} /> */}
                <BankLogo logo={account.bank.logoUrl} styles={{}} alt={`${account.bank.name} logo`} />
              </div>
              <div className={styles.info}>
                <div className={styles.bankName}>{startCase(account.bank.name)}</div>
                <div className={styles.accountDetails}>
                  {startCase(account.type)} • {getLast4(account.number)}
                </div>
              </div>
              {bankAccount.id === account.id ? (
                <div>
                  <CheckIcon className={styles.checkIcon} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.addAccountButtonContainer}>
        <CTA
          text="Añadir cuenta"
          size="large"
          onClick={() => setIsAddAccountModalOpen(true)}
        />
      </div>
      <AddBankAccount
        isOpen={isAddAccountModalOpen}
        setIsOpen={setIsAddAccountModalOpen}
        callback={handleNewAccountSuccess}
      />
    </div>
  );
};

const bankAccountShape = {
  id: PropTypes.number.isRequired,
  bank: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
  }),
  number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

ChooseBankAccount.propTypes = {
  bankAccount: PropTypes.exact(bankAccountShape),
  bankAccounts: PropTypes.arrayOf(PropTypes.exact(bankAccountShape)),
  handleAccountChange: PropTypes.func.isRequired,
  setBankAccount: PropTypes.func.isRequired,
  setBankAccounts: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
};

export default ChooseBankAccount;
