import { startCase } from 'utils/strings';

export const formatCode = (code) => {
  const [first6] = code.split('-');
  return first6.toUpperCase();
};

export const formatBankAccount = (bankAccount) => {
  const { bank, number } = bankAccount;
  return `${startCase(bank.name)} • ${number.slice(-4)}`;
};

export const toRGBA = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
