import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'lib/axios';
import routes from 'routes/routeList';
import useUser from 'hooks/useUser';
import useStore from 'store';

const useInterceptors = () => {
  const navigate = useNavigate();
  const { clearCurrentUser } = useUser();
  const { setNetworkError } = useStore();

  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    if (!error.response) {
      setNetworkError(error);
    }

    if (error.response.status === 401) {
      navigate(routes.login);
      clearCurrentUser();
    }

    throw error;
  };

  useEffect(() => {
    axios.interceptors.response.use(onSuccess, onError);
  }, []);
};

export default useInterceptors;
