import create from 'zustand';

const useStore = create((set) => ({
  user: null,
  isFormOpen: false,
  networkError: null,
  setIsFormOpen: (isFormOpen) => set({ isFormOpen }),
  setUser: (user) => set(() => ({ user })),
  setNetworkError: (networkError) => set(() => ({ networkError })),
}));

export default useStore;
