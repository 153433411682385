import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';

const Logo = ({ size }) => (
  <img src={logo} alt="logo" width={size === 'large' ? '95px' : '80px'} height="auto" />
);

Logo.propTypes = {
  size: PropTypes.string,
};

export default Logo;
