import axios from '../lib/axios';

export const addBankAccount = (data) => {
  return axios.post('/bank-accounts', data);
};

export const updateBankAccount = (accountId, data) => {
  return axios.patch(`/bank-accounts/${accountId}`, data);
};

export const deleteBankAccount = (accountId) => {
  return axios.delete(`/bank-accounts/${accountId}`);
};
