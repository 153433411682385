
import React, { useState } from 'react';
import styles from './biometrics-fingerprint.module.scss';
import onboardingStyles from '../../onboarding.module.scss';
import PropTypes from 'prop-types';
import CTA from 'components/CallToAction';
import { toast } from 'react-hot-toast';
import Input from 'components/Inputs/InputFloatingLabel';
import useForm from 'hooks/useForm';
import { checkIdentityNumber } from 'api/onboarding';
import useIsMounted from 'hooks/useIsMounted';
import ci from 'assets/ci.png';
const BiometricsFingerprint = ({ callback = () => { }, identityNumber }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { formData, handleInputChange } = useForm({
    fingerprint: '',
    identityNumber
  });

  const isMounted = useIsMounted();
  const handleSubmit = async () => {
    try {
      if (formData.fingerprint == '') {
        toast.error('El código dactilar es obligatorio.');
        return
      }

      if (formData.fingerprint.length < 6) {
        toast.error('El código dactilar debe tener como mínimo 6 caracteres.');
        return
      }

      if (formData.fingerprint.length > 25) {
        toast.error('El código dactilar es incorrecto.');
        return
      }

      if (formData.identityNumber == '') {
        toast.error('La cédula es obligatoria.');
        return
      }

      if (formData.identityNumber.length < 10 || formData.identityNumber.length > 10) {
        toast.error('La cédula debe tener 10 caracteres.');
        return
      }

      if (!/^[0-9]+$/.test(formData.identityNumber.toString())) {
        toast.error('La cédula solo puede tener números.');
        return
      }

      setLoading(true);
      setError('');
    
      const check = await checkIdentityNumber({ ...formData, fingerprint: formData.fingerprint.toUpperCase().slice(0, 6) });

      if (check.result) {
        toast.success('¡Éxito!');
        callback();
      } else {
        //  toast.success(check.detail);
        const msg = 'La cédula o código dactilar son incorrectos.'
        toast.error(msg);
        setError(msg);
      }
    } catch (error) {
      toast.error(error.response?.data);
      setError(error.response?.data);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };
  return (
    <div className={styles.content}>
      {/* <h3 style={{ marginBottom: '15px' }}>Agrega los primeros 6 caracteres de tu código dactilar</h3> */}
      <div className={onboardingStyles.centerContainer}>
        <h3 style={{ marginBottom: '15px' }}>Valida tu cédula</h3>
      </div>
      <img src={ci} alt="ci" style={{ paddingRight: '30px', paddingLeft: '30px' }} />

      {/* <h5 style={{ marginBottom: '15px' }}>Agrega los primeros 6 caracteres de tu código dactilar</h5> */}
      {/* <h3 style={{ marginBottom: '0px' }}>Crea una contraseña</h3> */}
      {/* <h4 style={{ marginBottom: '15px' }}>Agrega los primeros 6 caracteres de tu código dactilar</h4> */}
      <Input
        label="Cédula"
        name="identityNumber"
        value={formData.identityNumber}
        onChange={handleInputChange}
        type="text"
        // maxLength="6"
        // minLength="6"
        inputMode="text"
        pattern="[0-9]*"
      />
      <div style={{ margin: '15px' }}></div>
      <Input
        label="Digita tu código dactilar"
        name="fingerprint"
        value={formData.fingerprint}
        onChange={handleInputChange}
        type="text"
        // maxLength="6"
        // minLength="6"
        autoFocus={true}
        inputMode="text"
        pattern="[0-9A-Z]*"
      />
      {/* <span
        className={onboardingStyles.caption}
      >{`Solo los 6 primeros caracteres`}</span> */}
      {error != '' && <div className={onboardingStyles.alertError}>{error}</div>}
      <div style={{ margin: '15px' }}></div>
      <CTA
        text={'Continuar'}
        size="large"
        form="Biometrics"
        isLoading={loading}
        onClick={handleSubmit}
      />
    </div>
  );
};

BiometricsFingerprint.propTypes = {
  callback: PropTypes.func,
  identityNumber: PropTypes.string.isRequired,
};

export default BiometricsFingerprint;