export const isValidNumber = (char) => {
  return /^[0-9.]*$/.test(char);
};

export const toDollars = (number, decimals = 2) => {
  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return money.format(number);
};

export const isValidDollarAmount = (value) => {
  // Add "." to the regex to allow for decimals
  const regex = /^[0-9]*$/;

  if (!regex.test(value)) return false;

  const split = value.split('.');
  if (split.length > 2) return false;

  const dollars = split[0];
  const cents = split[1];
  if (dollars.length > 5) return false;
  if (cents && cents.length > 2) return false;

  return true;
};
