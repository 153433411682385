import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiEyeOffLine as Hidden } from '@react-icons/all-files/ri/RiEyeOffLine';
import { RiEyeLine as Shown } from '@react-icons/all-files/ri/RiEyeLine';
import Input from '../InputFloatingLabel';
import styles from './input-password.module.scss';

const InputPassword = ({
  label,
  isNew,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  inputMode,
  pattern,
  caption,
  inputRef,
}) => {
  const [isShown, setIsShown] = useState(false);
  const togglePassword = () => {
    setIsShown(!isShown);
  };

  return (
    <div className={styles.container}>
      <Input
        inputRef={inputRef}
        label={label}
        caption={caption}
        type={isShown ? 'text' : 'password'}
        autoComplete={isNew ? 'new-password' : 'on'}
        autoCapitalize="none"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        inputMode={inputMode}
        pattern={pattern}
      />
      <div className={styles.iconContainer} onClick={togglePassword}>
        {isShown ? <Shown className={styles.icon} /> : <Hidden className={styles.icon} />}
      </div>
    </div>
  );
};

InputPassword.propTypes = {
  isNew: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  caption: PropTypes.string,
  inputRef: PropTypes.object,
};

export default InputPassword;
