import React, { useState } from 'react';
import styles from './finished-process.module.scss';
import { finishProcess } from 'api/onboarding';
import onboardingStyles from '../../onboarding.module.scss';
import CTA from 'components/CallToAction';
import hiFivePng from 'assets/decorative-images/Hi-Five.png';
const FinishedProcess = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const finish = async () => {
    try {
      setLoading(true);
      setError('');
      await finishProcess();
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setError(error.response?.data);
    }
  };
  return (
    <div className={styles.content}>
      <img
        src={hiFivePng}
        alt="success high-five"
        className={styles.image}
      />
      <h2 className={styles.title}>¡Registro Exitoso!</h2>
      <p className={styles.message}>
        Ahora puedes disfrutar de los beneficios de obtener anticipos de tu sueldo de
        manera rápida y sencilla.
      </p>
      {error != '' && <div className={onboardingStyles.alertError}>{error}</div>}
      <br />
      <CTA
        text="Ingresar a Misú"
        size="large"
        form="FinishedProcess"
        isLoading={loading}
        onClick={finish}
      />
    </div>
  );
};
export default FinishedProcess;
