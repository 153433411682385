import React from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { RiCheckLine as CheckIcon } from '@react-icons/all-files/ri/RiCheckLine';
import { toDollars } from 'utils/currency';
import { startCase } from 'utils/strings';
import styles from './choose-option.module.scss';

const ChooseProcessingOption = ({
  processingOptions,
  processingOption,
  setProcessingOption,
  setScreen,
}) => {
  const handleClick = (option) => {
    setProcessingOption(option);
    setTimeout(() => {
      setScreen('main');
    }, 300);
  };

  return (
    <div>
      <div className={styles.header}>
        <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
        <span>Opción de procesamiento</span>
      </div>
      <div className={styles.content}>
        {processingOptions.map((option) => {
          const Icon = option.icon;
          const isActive = option.id === processingOption.id;

          return (
            <div
              key={option.id}
              className={`${styles.option} ${isActive ? styles.active : ''}`}
              onClick={() => handleClick(option)}
            >
              <Icon className={styles.optionIcon} />
              <div className={styles.infoContainer}>
                <p className={styles.name}>{startCase(option.name)}</p>
                <p className={styles.fee}>
                  {option.hoursAdjusted}
                  {' horas • '}
                  {option.fee > 0 ? toDollars(option.fee) : 'Incluido'}
                </p>
              </div>
              {isActive && <CheckIcon className={styles.checkIcon} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const processingOptionShape = PropTypes.exact({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fee: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  hoursAdjusted: PropTypes.number.isRequired,
  bankId: PropTypes.number.isRequired,
  expectedFundingDate: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
});

ChooseProcessingOption.propTypes = {
  processingOptions: PropTypes.arrayOf(processingOptionShape),
  processingOption: processingOptionShape,
  setProcessingOption: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
};

export default ChooseProcessingOption;
