// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iyoi8D_xussbI1MNuhfw{display:flex;align-items:center}.NPzDySx42LNmh3dcS_6w{height:20px;min-height:20px;width:20px;min-width:20px;margin-right:.5rem}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/Checkbox/checkbox.module.scss","webpack://./src/styles/variables/_space.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,eAAA,CACA,UAAA,CACA,cAAA,CACA,kBCVQ","sourcesContent":["@import 'styles/variables';\n\n.container {\n  display: flex;\n  align-items: center;\n}\n\n.checkbox {\n  height: 20px;\n  min-height: 20px;\n  width: 20px;\n  min-width: 20px;\n  margin-right: $space-1;\n}\n","$space-unit: 1rem;\n\n$space-1: 0.5 * $space-unit;\n$space-2: 1 * $space-unit;\n$space-3: 1.5 * $space-unit;\n$space-4: 2 * $space-unit;\n$space-5: 2.5 * $space-unit;\n$space-6: 3 * $space-unit;\n$space-7: 3.5 * $space-unit;\n$space-8: 4 * $space-unit;\n$space-9: 4.5 * $space-unit;\n$space-10: 5 * $space-unit;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `iyoi8D_xussbI1MNuhfw`,
	"checkbox": `NPzDySx42LNmh3dcS_6w`
};
export default ___CSS_LOADER_EXPORT___;
