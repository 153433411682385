import React from 'react';
import useStore from 'store';
import wave from './assets/wave.svg';
import styles from './summary.module.scss';
import { toDollars } from '../../../../utils/currency';

const Summary = () => {
  const user = useStore((state) => state.user);
  const { firstName, usedQuota = 0, quota = 0 } = user;

  const available = toDollars(quota - usedQuota, 0);
  const limit = toDollars(quota, 0);
  const used = toDollars(usedQuota, 0);

  const details = [
    { title: 'Solicitado', value: used, hex: '#F4B942' },
    { title: 'Disponible', value: available, hex: '#44BBA4' },
    { title: 'Cupo', value: limit, hex: '#4361EE' },
  ];

  return (
    <div className={styles.wrapper}>
      <img src={wave} alt="wave" className={styles.wave} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <p className={styles.greeting}>Bienvenido, {firstName}</p>
            <p className={styles.availableQuota}>{`Tienes ${available} disponibles.`}</p>
          </div>
          <div className={styles.details}>
            <div className={styles.topDetails}>
              {details.map((detail) => (
                <div key={detail.title} className={styles.detailContainer}>
                  <div className={styles.detailHide}>{'-'}</div>

                  <div className={styles.detailTitle}>{detail.title}</div>

                  <div>
                    <span
                      className={styles.detailDot}
                      style={{ backgroundColor: detail.hex }}
                    ></span>
                  </div>
                  <div className={styles.detailValue}>{detail.value}</div>
                </div>
              ))}
            </div>
            <div className={styles.bottomDetails}>
              {`Has solicitado ${used} de tu cupo mensual. Tienes ${available} disponibles.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
