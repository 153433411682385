import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Loading from 'components/Loading';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { getOneLoan } from 'api/user';
import { startCase } from 'utils/strings';
import { toDollars } from 'utils/currency';
import { toCompactDate } from 'utils/dates';
import { formatCode, formatBankAccount, toRGBA } from '../../utils/helpers';
import statusColors from '../../utils/statusColors';
import useIsMounted from 'hooks/useIsMounted';
import styles from './loan-modal.module.scss';
import sorryPng from 'assets/decorative-images/Sorry.png';

const ErrorScreen = ({ handleCloseRequest }) => {
  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <CloseIcon className={styles.closeIcon} onClick={handleCloseRequest} />
        <p>Error</p>
      </div>
      <div className={`${styles.contentWrapper} ${styles.errorWrapper}`}>
        <div>
          <h3 className={styles.errorTitle}>Error al cargar anticipo</h3>
          <p className={styles.errorMessage}>
            Lo sentimos, pero no pudimos cargar la información de este anticipo. Por
            favor, intentalo nuevamente más tarde.
          </p>
          <img
            src={sorryPng}
            alt="Error"
            className={styles.errorImage}
          />
        </div>
      </div>
    </div>
  );
};

const LoanContent = ({ loan, handleCloseRequest }) => {
  const summaryItems = [
    { label: 'Fecha solicitud', value: toCompactDate(loan.requestDate) },
    { label: 'Código de referencia', value: formatCode(loan.referenceCode) },
    { label: 'Fecha depósito', value: toCompactDate(loan.expectedFundingDate) },
    {
      label: 'Cuenta de banco',
      value: formatBankAccount(loan.bankAccount),
      className: styles.bankAccount,
    },
    { label: 'Fecha vencimiento', value: toCompactDate(loan.expectedRepaymentDate) },
    { label: 'Tipo de procesamiento', value: startCase(loan.processingOption.name) },
  ];

  const details = [
    { label: 'Anticipo', value: toDollars(loan.requestedAmount) },
    { label: 'Uso de plataforma', value: toDollars(loan.platformFee) },
    { label: 'Tarifa de procesamiento', value: toDollars(loan.processingFee) },
    { label: 'Monto a retener', value: toDollars(loan.repaymentAmount) },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.modalHeader}>
        <CloseIcon className={styles.closeIcon} onClick={handleCloseRequest} />
        <p className={styles.title}>Detalles del anticipo</p>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryHeader}>
              <div className={styles.loanAmount}>{toDollars(loan.requestedAmount)}</div>
              <div
                className={styles.statusContainer}
                style={{
                  backgroundColor: toRGBA(statusColors[loan.status], 0.1),
                  color: statusColors[loan.status],
                }}
              >
                <span>{loan.status == 'incobrable' ? startCase('vencido') : startCase(loan.status)}</span>
              </div>
            </div>
            <div className={styles.summary}>
              {summaryItems.map((item) => (
                <div key={item.label} className={item.className}>
                  <p className={styles.summaryLabel}>{item.label}</p>
                  <p className={styles.summaryValue}>{item.value}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.detailsWrapper}>
            <h3 className={styles.header}>Desglose</h3>
            <div className={styles.detailsContainer}>
              {details.map((item) => (
                <div key={item.label} className={styles.detail}>
                  <span className={styles.label}>{item.label}</span>
                  <span className={styles.value}>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <h3 className={styles.header}>Pagos</h3>
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Forma de pago</th>
                    <th className={styles.paymentAmount}>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  {loan.payments.length > 0 ? (
                    loan.payments.map((payment) => (
                      <tr key={payment.id}>
                        <td>{toCompactDate(payment.date)}</td>
                        <td>{startCase(payment.type)}</td>
                        <td className={styles.paymentAmount}>
                          {toDollars(payment.amount)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className={styles.emptyStateRow}>
                        No se han realizado pagos
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoanModal = ({ loanId, isOpen, setIsOpen }) => {
  const isMounted = useIsMounted();
  const [loan, setLoan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseRequest = () => {
    setIsOpen(false);

    // Reset state
    setLoan(null);
    setIsLoading(true);
  };

  const handleOpen = async () => {
    setIsLoading(true);
    const start = Date.now();
    const minMS = 500;

    try {
      const data = await getOneLoan(loanId);

      if (isMounted.current) setLoan(data);
    } catch (error) {
      if (isMounted.current) setLoan(null);
    } finally {
      const end = Date.now();
      const time = end - start;
      if (time < minMS) {
        await new Promise((resolve) => setTimeout(resolve, minMS - time));
      }

      if (isMounted.current) setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseRequest}
      onAfterOpen={handleOpen}
      contentLabel="Detalles del Anticipo"
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <div className={styles.wrapper}>
        {isLoading ? (
          <Loading isFluid={true} />
        ) : loan ? (
          <LoanContent loan={loan} handleCloseRequest={handleCloseRequest} />
        ) : (
          <ErrorScreen handleCloseRequest={handleCloseRequest} />
        )}
      </div>
    </Modal>
  );
};

LoanModal.propTypes = {
  loanId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

LoanContent.propTypes = {
  loan: PropTypes.object.isRequired,
  handleCloseRequest: PropTypes.func.isRequired,
};

ErrorScreen.propTypes = {
  handleCloseRequest: PropTypes.func.isRequired,
};

export default LoanModal;
