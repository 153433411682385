import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cTAStyles from 'components/CallToAction/cta.module.scss';
import onboardingStyles from '../../onboarding.module.scss';

import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { finishDemographic } from 'api/onboarding';
import { toast } from 'react-hot-toast';
import useIsMounted from 'hooks/useIsMounted';
const schema = yup.object().shape({
  selectedCompany: yup.string().required('Debes seleccionar una opción.'),
});

const defaultValues = {
  selectedCompany: '',
};

const Demographic = ({ companies, callback = () => {} }) => {
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setError('');
      await finishDemographic({
        selectedCompanyRegistrationNumber: data.selectedCompany,
      });
      toast.success('¡Éxito!');
      // callback();
      window.location.reload();
    } catch (error) {
      setError(error.response?.data);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <form
      style={{ paddingLeft: '20px', paddingRight: '20px' }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error != '' && <div className={onboardingStyles.alertError}>{error}</div>}
      <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
        <h3 style={{ marginBottom: '15px' }}>¿En qué empresa trabajas?</h3>
        {companies.map((company) => (
          <div key={company.value} style={{ marginBottom: '12px' }}>
            <label style={{ display: 'flex' }}>
              <Controller
                style={{ flex: 1 }}
                name="selectedCompany"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="radio"
                    {...field}
                    style={{
                      marginRight: '5px',
                      width: '22px',
                      height: '22px',
                      cursor: 'pointer',
                    }}
                    onChange={() =>
                      reset({
                        selectedCompany: company.value,
                      })
                    }
                  />
                )}
              />
              <h5 style={{ flex: 1 }}>{company.label}</h5>
            </label>
          </div>
        ))}

        {errors.selectedCompany && (
          <p style={{ marginBottom: '12px', color: 'red' }}>
            {errors.selectedCompany.message}
          </p>
        )}

        <button
          style={{ marginBottom: '25px', marginTop: '25px' }}
          type="submit"
          className={`${cTAStyles.button} ${cTAStyles['large']}`}
          disabled={!isValid || loading}
        >
          {loading ? <div className={cTAStyles.loader}></div> : 'Enviar'}
        </button>
      </div>
    </form>
  );
};

Demographic.propTypes = {
  companies: PropTypes.any.isRequired,
  callback: PropTypes.func,
};

export default Demographic;
