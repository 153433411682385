import React from 'react';
import PropTypes from 'prop-types';
import styles from './show-message.module.scss';
import bookPng from 'assets/decorative-images/Book.png';

const ShowMessage = ({ title, message }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{message}</p>
        <img className={styles.image} src={bookPng} alt="" />
      </div>
    </div>
  );
};

ShowMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};


export default ShowMessage;
