import useStore from 'store';

const useUser = () => {
  const { user, setUser } = useStore((state) => state);

  const setCurrentUser = (currentUser) => {
    setUser(currentUser);
  };

  const clearCurrentUser = () => {
    setUser(null);
  };

  return {
    user,
    setCurrentUser,
    clearCurrentUser,
  };
};

export default useUser;
