import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { RiArrowRightSLine as RightArrow } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { RiErrorWarningLine as WarningIcon } from '@react-icons/all-files/ri/RiErrorWarningLine';
import { getTyR } from 'api/termUser';
import CTA from 'components/CallToAction';
import useStore from 'store';
import { toDollars, isValidDollarAmount } from 'utils/currency';
import { startCase, getLast4 } from 'utils/strings';
import { createLoan } from 'api/loan';
import styles from './main-screen.module.scss';
import { getQuotaTransaction } from 'api/setting';
import BankLogo from '../../../../../components/BankLogo';
const MainScreen = ({
  processingOption,
  bankAccount,
  setLoan,
  setTermId,
  setScreen,
  handleModalClose,
  amount,
  setAmount,
}) => {
  const amountInput = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const user = useStore((state) => state.user);
  const [quotaTransaction, setQuotaTransaction] = useState(0);
  const { quota, usedQuota } = user;
  const availableQuota = quota - usedQuota;
  const handleSubmit = async () => {
    setIsLoading(true);

    const start = Date.now();
    const minSecs = 1000;

    try {
      const loan = await createLoan({
        requestedAmount: amount,
        bankAccountId: bankAccount.id,
        processingOptionId: processingOption.id,
      });

      const end = Date.now();
      const time = end - start;
      if (time < minSecs) {
        await new Promise((resolve) => setTimeout(resolve, minSecs - time));
      }

      setLoan(loan);
      const terms = await getTyR();
      setTermId(terms.termId);
      setIsLoading(false);
      setScreen('review');
    } catch (error) {
      toast.error(error.response?.data);
      setIsLoading(false);
    }
  };

  const setAmountInputWidth = (amountValue) => {
    const numWidth = 34;
    const dotWidth = 14;

    const [dollars, cents] = amountValue.split('.');
    const widthDollars = dollars.length * numWidth;
    const widthCents = cents ? cents.length * numWidth : 0;
    const widthDot = amountValue.includes('.') ? dotWidth : 0;
    const totalWidth = widthDollars + widthCents + widthDot;

    amountInput.current.style.width = `${totalWidth}px`;
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    if (!isValidDollarAmount(value)) return;

    setAmountInputWidth(value);
    setAmount(value);
  };

  useEffect(async () => {
    setAmountInputWidth(amount);
    const quotaTransactionData = await getQuotaTransaction();
    setQuotaTransaction(quotaTransactionData.quotaTransaction || 0);
  }, []);

  useEffect(() => {
    if (amountInput.current) {
      amountInput.current.focus();
    }
  }, [amountInput]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CloseIcon className={styles.closeIcon} onClick={handleModalClose} />
        <span>Nuevo anticipo</span>
      </div>
      <div className={styles.content}>
        <div className={styles.amountWrapper}>
          <div>
            <div className={styles.amountContainerOuter}>
              <div
                className={`${styles.amountContainerInner} ${amount.length > 0 ? styles.active : ''
                  }`}
              >
                <div className={styles.dollarSign}>$</div>
                <input
                  ref={amountInput}
                  className={styles.amountInput}
                  type="text"
                  inputMode="numeric"
                  autoComplete="off"
                  pattern="[0-9]*"
                  placeholder="0"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </div>
            <p className={styles.amountMessage}>{`Puedes solicitar hasta ${toDollars(
              availableQuota,
            )}`}</p>
            {quotaTransaction > 0 && amount > quotaTransaction && (
              <>
                <br />
                <span
                  style={{
                    background: 'rgba(255, 143, 14, 0.1)',
                    color: 'rgb(255, 143, 14)',

                    // background: '#ff8f0e',
                    // color: '#000000',
                    fontSize: '0.9rem',
                    fontWeight: '700',
                    padding: '0.25rem 0.4rem',
                    borderRadius: '6px',
                  }}
                >
                  {`El monto máximo por transacción es ${toDollars(quotaTransaction)}`}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.optionsContainer}>
            <div className={styles.option} onClick={() => setScreen('chooseAccount')}>
              <div className={styles.optionImageContainer}>
                {/* <img src={bankAccount.bank.logoUrl} alt={bankAccount.bank.name} /> */}
                <BankLogo logo={bankAccount.bank.logoUrl} styles={{}} alt={`${bankAccount.bank.name} logo`} />
              </div>
              <div className={styles.optionDescriptionContainer}>
                <p className={styles.optionDescriptionTop}>
                  {startCase(bankAccount.bank.name)}
                </p>
                <p className={styles.optionDescriptionBottom}>
                  {startCase(bankAccount.type)} • {getLast4(bankAccount.number)}
                </p>
              </div>
              <div className={styles.optionIconContainer}>
                <RightArrow className={styles.optionIcon} />
              </div>
            </div>
            {processingOption ? (
              <div className={styles.option} onClick={() => setScreen('chooseOption')}>
                <div className={styles.optionImageContainer}>
                  <processingOption.icon className={styles.processingOptionIcon} />
                </div>
                <div className={styles.optionDescriptionContainer}>
                  <p className={styles.optionDescriptionTop}>
                    {startCase(processingOption.name)}
                  </p>
                  <p className={styles.optionDescriptionBottom}>
                    {processingOption.hoursAdjusted}
                    {' horas • '}
                    {processingOption.fee > 0
                      ? toDollars(processingOption.fee)
                      : 'Incluido'}
                  </p>
                </div>
                <div className={styles.optionIconContainer}>
                  <RightArrow className={styles.optionIcon} />
                </div>
              </div>
            ) : (
              <div className={`${styles.option} ${styles.disabled}`}>
                <div className={styles.optionImageContainer}>
                  <WarningIcon className={styles.processingOptionIcon} />
                </div>
                <div className={styles.optionDescriptionContainer}>
                  <p className={styles.optionDescriptionTop}>
                    No hay opciones disponibles
                  </p>
                  <p className={styles.optionDescriptionBottom}>{'-'}</p>
                </div>
                <div className={styles.optionIconContainer}>
                  <RightArrow className={styles.optionIcon} />
                </div>
              </div>
            )}
          </div>
          <CTA
            text="Continuar"
            size="large"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={
              !amount ||
              amount > availableQuota ||
              !processingOption ||
              !bankAccount ||
              (quotaTransaction > 0 && amount > quotaTransaction)
            }
          />
        </div>
      </div>
    </div>
  );
};

MainScreen.propTypes = {
  amount: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  setLoan: PropTypes.func.isRequired,
  setTermId: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  bankAccount: PropTypes.exact({
    id: PropTypes.number.isRequired,
    bank: PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
    }),
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
  }),
  processingOption: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fee: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    hoursAdjusted: PropTypes.number.isRequired,
    bankId: PropTypes.number.isRequired,
    expectedFundingDate: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.func,
  }),
};

export default MainScreen;
