import axios from '../lib/axios';
export const getTyC = () => {
  return axios.get('/term-user/getTyC');
};

export const createTyC = ({ termId }) => {
  return axios.post('/term-user/createTyC', { termId });
};

export const getTyR = () => {
  return axios.get('/term-user/getTyR');
};

export const needsAcceptTermsNewUser = ({ email, password }) => {
  return axios.post('/term-user/needsAcceptTermsNewUser', { email, password });
};

export const getTyCToNewUser = ({ email, password }) => {
  return axios.post('/term-user/getTyCToNewUser', { email, password });
};

export const getTyRByUserLoanTerm = (loanId, termId) => {
  return axios.get(`/term-user/getTyRByUserLoanTerm/${loanId}/${termId}`);
};