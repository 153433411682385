import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import NotFound from '../components/NotFound';
import routes from './routeList';

// Most important components
import Home from '../views/Home';

// Lazy loaded components
const Login = lazy(() => import('../views/Login'));
const Logout = lazy(() => import('../views/Logout'));
const Profile = lazy(() => import('../views/Profile'));
const RecoverPassword = lazy(() => import('../views/RecoverPassword'));

const AppRoutes = () => (
  <Routes>
    <Route path={routes.login} element={<Login />} />
    <Route path={routes.logout} element={<Logout />} />
    <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
    <Route
      path={`${routes.profile}/*`}
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path={routes.home}
      element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
