import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import config from 'config';
import App from './App';
import './styles/app.scss'; // Load app styles

Modal.setAppElement('#root'); // Set the root element for the modal
window.ECLIPSOFT_ID4FACE_URL = config.eclipsoftId4faceUrl;
window.ECLIPSOFT_ID4FACE_INTEGRITY = config.eclipsoftId4faceIntegrity;
const root = document.getElementById('root');
ReactDOM.render(<App />, root);
