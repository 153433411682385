import config from 'config';
import routes from 'routes/routeList';

const isUserApproved = (user) => {
  const { userStatuses } = config;
  if (!user) return false;
  return user.approvalStatus === userStatuses.approved;
};

const getCurrentRoute = (pathname) => {
  const split = pathname.split('/');
  return '/' + split[1];
};

const routeExists = (route) => {
  return Object.values(routes).some((r) => r === route);
};

const isPageWithoutNavbar = (route) => {
  const { logout, registration } = routes;
  return [logout, registration].includes(route);
};

const shouldHideNavbar = (pathname) => {
  const route = getCurrentRoute(pathname);

  if (isPageWithoutNavbar(route)) return true;
  if (!routeExists(route)) return true;

  return false;
};

const shouldRenderLeanNavbar = (pathname) => {
  const route = getCurrentRoute(pathname);

  const { login, recoverPassword } = routes;
  return [login, recoverPassword].includes(route);
};

export default {
  isUserApproved,
  shouldHideNavbar,
  shouldRenderLeanNavbar,
};
