import React from 'react';
import Logo from 'components/Logo';
import styles from './network-error.module.scss';
import sorryPng from 'assets/decorative-images/Sorry.png';

const NetworkError = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Logo size="large" />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>Error de conexión</h2>
          <p className={styles.message}>
            Lo sentimos, pero no podemos conectar con el servidor. Por favor, intenta
            nuevamente más tarde o contacta con soporte.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={sorryPng}
            alt="Error"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default NetworkError;