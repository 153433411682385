import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftLine as ArrowLeft } from '@react-icons/all-files/ri/RiArrowLeftLine';
import { toast } from 'react-hot-toast';
import CTA from 'components/CallToAction';
import { confirmLoanRequest } from 'api/loan';
import { toDollars } from 'utils/currency';
import { getMonthName, toDateWithTime } from 'utils/dates';
import { getLast4 } from 'utils/strings';
import config from 'config';
import styles from './review.module.scss';
import Checkbox from 'components/Inputs/Checkbox';
import { TermsTYRModal } from 'components/Terms/Terms';
const Review = ({ termId, loan, setLoan, setIsCompleted, setScreen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [tyRModalOpen, setTyRModalOpen] = useState(false);

  const handleClick = async () => {
    if (!isAgreed) return;

    setIsLoading(true);
    const start = Date.now();
    const minMS = 3000;

    try {
      const confirmedLoan = await confirmLoanRequest(loan.id, termId);
      setLoan(confirmedLoan);

      const end = Date.now();
      const time = end - start;
      const timeLeft = minMS - time;

      if (timeLeft > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeLeft));
      }

      setIsCompleted(true);
      setIsLoading(false);
      setScreen('confirmation');
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <ArrowLeft className={styles.backIcon} onClick={() => setScreen('main')} />
          Revisa tu solicitud
        </div>
        <div className={styles.content}>
          <div className={styles.topContent}>
            <div className={styles.amountWrapper}>
              <div className={styles.amount}>
                <div className={styles.amountSymbol}>$</div>
                <div className={styles.amountNumber}>
                  {loan.repaymentAmount.toFixed(2)}
                </div>
              </div>
              <div className={styles.amountCaption}>
                A retener al final del mes de{' '}
                <span>{getMonthName(loan.expectedRepaymentDate)}</span>
              </div>
            </div>
          </div>
          <div className={styles.bottomContent}>
            <div className={styles.detailsContainer}>
              <div className={styles.detail}>
                <div className={styles.detailHeader}>Anticipo</div>
                <div className={styles.detailBody}>
                  <div className={styles.detailItem}>
                    <span>Cuenta</span>
                    <span>
                      {loan.bankAccount.bank.name} • {getLast4(loan.bankAccount.number)}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span>Fecha a recibir</span>
                    <span>{toDateWithTime(loan.expectedFundingDate)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.detail}>
                <div className={styles.detailHeader}>Retención</div>
                <div className={styles.detailBody}>
                  <div className={styles.detailItem}>
                    <span>Anticipo</span>
                    <span>{toDollars(loan.requestedAmount)}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span>Uso de plataforma</span>
                    <span>{toDollars(loan.platformFee)}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span>Tarifa de procesamiento</span>
                    <span>{toDollars(loan.processingFee)}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span>Total a retener</span>
                    <span>{toDollars(loan.repaymentAmount)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Checkbox
                isChecked={isAgreed}
                onChange={() => setIsAgreed(!isAgreed)}
                label={
                  <span className={styles.disclaimer}>
                    {`He leído y acepto los `}
                    {/* <a href={config.termsUrl} rel="noreferrer" target="_blank">
                      términos de referencia del contrato
                    </a> */}
                    <a
                      style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => {
                        setTyRModalOpen(true);
                      }}
                    >
                      términos de referencia del contrato
                    </a>
                  </span>
                }
              />
              <CTA
                text="Solicitar anticipo"
                size="large"
                isLoading={isLoading}
                disabled={!loan || !isAgreed}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
      <TermsTYRModal
        termId={termId}
        loanId={loan.id}
        isOpen={tyRModalOpen}
        setIsOpen={setTyRModalOpen}
      />
    </>
  );
};

Review.propTypes = {
  termId: PropTypes.number,
  loan: PropTypes.object,
  setLoan: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  setIsCompleted: PropTypes.func.isRequired,
};

export default Review;
