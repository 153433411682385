import React from 'react';
import PropTypes from 'prop-types';
import styles from './floating.module.scss';

const InputFloatingLabel = ({
  label,
  type,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  autoComplete,
  autoCapitalize,
  inputMode,
  pattern,
  caption,
  inputRef,
  autoFocus = false
}) => {
  return (
    <div className={styles.container}>
      <input
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        inputMode={inputMode}
        pattern={pattern}
        autoFocus={autoFocus}
      />
      {caption && <span className={styles.caption}>{caption}</span>}
      <label className={value && styles.filled}>{label}</label>
    </div>
  );
};

InputFloatingLabel.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCapitalize: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  caption: PropTypes.string,
  inputRef: PropTypes.object,
  autoFocus: PropTypes.bool,
};

export default InputFloatingLabel;
