import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';

const Checkbox = ({ label, isChecked, onChange }) => (
  <div className={styles.container}>
    <input
      className={styles.checkbox}
      type="checkbox"
      name={label}
      checked={isChecked}
      onChange={onChange}
    />
    <label>{label}</label>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
