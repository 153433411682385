const monthsShort = {
  0: 'Ene',
  1: 'Feb',
  2: 'Mar',
  3: 'Abr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Ago',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dic',
};

const monthsLong = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre',
};

export const isValidDateChar = (char) => {
  return /^[0-9/]*$/.test(char);
};

const getPositions = (dateFormat) => {
  const positions = {
    'dd/mm/yyyy': [2, 5],
    'mm/yyyy': [2],
  };

  return positions[dateFormat.toLowerCase()] || [];
};

const addDateSeparator = (newDate, currentDate, positions) => {
  /*
    Add a date separator to string. 30082021 => 30/08/2021
    Input: string ~ 30
    Output: string ~ 30/
  */

  const separator = '/';
  const length = newDate.length;

  if (positions.includes(length)) {
    // Remove separator on backspace
    const currentLastChar = currentDate[currentDate.length - 1];
    if (currentLastChar === separator) {
      return currentDate.slice(0, currentDate.length - 2);
    } else {
      // Add separator
      return (newDate += separator);
    }
  } else {
    return newDate;
  }
};

export const handleDateInputChange = (event, currentValue, dateFormat, callback) => {
  const { value } = event.target;

  // This are the positions (index) in which the separator will be added
  const positions = getPositions(dateFormat);

  if (isValidDateChar(value) && value.length <= dateFormat.length) {
    const formattedValue = addDateSeparator(value, currentValue, positions);
    event.target.value = formattedValue;

    return callback(event);
  }
};

export const toISOFormat = (date) => {
  const dateArray = date.split('/');
  const year = dateArray[2];
  const month = dateArray[1];
  const day = dateArray[0];

  return `${year}-${month}-${day}`;
};

export const toDateWithTime = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth();
  const minutes = date.getMinutes();
  const hours = date.getHours();

  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const monthName = monthsLong[month].toLowerCase();

  return `${day} de ${monthName}, ${formattedHours}:${formattedMinutes} ${period}`;
};

export const getMonthName = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth();
  return monthsLong[month].toLowerCase();
};

export const fromISOToMonthAndYear = (dateString) => {
  /* 
    This function returns the month and year of a date in ISO format
    INPUT: dateString ~ 2020-02-02T00:00:00.000Z
    OUTPUT: string ~ Febrero 2020
  */

  const [date] = dateString.split('T');
  const [year, month] = date.split('-');
  const monthName = monthsLong[parseInt(month) - 1];

  return `${monthName} ${year}`;
};

export const fromISOToCompactDate = (dateString) => {
  /* 
    This function converts a date in ISO format to a compact date format

    Input: string ~ 2020-02-20T00:00:00.000Z
    Output: string ~ Feb 20, 2020
  */

  const [date] = dateString.split('T');
  const [year, month, day] = date.split('-');
  const dayNumber = parseInt(day); // Remove leading zero
  const monthNum = parseInt(month) - 1; // JS months are 0-indexed

  return `${monthsShort[monthNum]} ${dayNumber}, ${year}`;
};

export const toCompactDate = (dateString) => {
  /* 
    This function converts a date in ISO format to a compact date format

    Input: string ~ 2020-02-20T00:00:00.000Z
    Output: string ~ Feb 20, 2020
  */

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${monthsShort[month]} ${day}, ${year}`;
};
