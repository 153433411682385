// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LkK7Z9KtOWznUDLzrRx5{display:grid;gap:1rem;width:100%;padding:35px}.LkK7Z9KtOWznUDLzrRx5 input{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Onboarding/components/PasswordChange/password-change.module.scss","webpack://./src/styles/variables/_space.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,QCDQ,CDER,UAAA,CACA,YAAA,CAEA,4BACE,UAAA","sourcesContent":["@import 'styles/variables';\n\n.form {\n  display: grid;\n  gap: $space-2;\n  width: 100%;\n  padding: 35px;\n\n  input {\n    width: 100%;\n  }\n}\n\n// .caption {\n//   display: block;\n//   font-size: $font-size-s;\n//   color: $font-color-caption;\n//   margin-top: calc($space-1 / 2);\n// }\n","$space-unit: 1rem;\n\n$space-1: 0.5 * $space-unit;\n$space-2: 1 * $space-unit;\n$space-3: 1.5 * $space-unit;\n$space-4: 2 * $space-unit;\n$space-5: 2.5 * $space-unit;\n$space-6: 3 * $space-unit;\n$space-7: 3.5 * $space-unit;\n$space-8: 4 * $space-unit;\n$space-9: 4.5 * $space-unit;\n$space-10: 5 * $space-unit;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `LkK7Z9KtOWznUDLzrRx5`
};
export default ___CSS_LOADER_EXPORT___;
