import React from 'react';
import { RiWhatsappLine as HelpIcon } from '@react-icons/all-files/ri/RiWhatsappLine';
import config from 'config';
import logo from 'assets/logo.svg';
import styles from '../navbar.module.scss';

const LeanNavbar = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <a href={config.landingPageUrl} className={styles.logo}>
          <img src={logo} alt="Logo" />
        </a>
        <a href={config.helpLink} rel="noreferrer" target="_blank">
          <button className={styles.helpButton}>
            <HelpIcon />
            <span>Ayuda</span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default LeanNavbar;
