import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RiArrowDownSLine as ClosedIcon } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { RiArrowUpSLine as OpenIcon } from '@react-icons/all-files/ri/RiArrowUpSLine';

import Input from 'components/Inputs/InputFloatingLabel';
import useOutsideClick from 'hooks/useOutsideClick';
import styles from './select.module.scss';

const SearchSelect = ({
  label,
  options = [],
  defaultOption,
  charsToOpenOptions = 0,
  handleSelect,
  autoComplete,
}) => {
  const [selected, setSelected] = useState(defaultOption);
  const [isListOpen, setIsListOpen] = useState(false);
  const [inputValue, setInputValue] = useState(defaultOption ? defaultOption.label : '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setInputValue(selected ? selected.label : '');

    if (isListOpen) setIsListOpen(false);
  });

  const handleInputChange = (e) => {
    const { value } = e.target;

    const hasEnoughChars = value.length >= charsToOpenOptions;
    const matches = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    );

    setInputValue(value);
    setFilteredOptions(matches);

    if (hasEnoughChars && matches.length > 0) {
      setIsListOpen(true);
    } else {
      setIsListOpen(false);
    }
  };

  const handleFocus = () => {
    if (inputValue.length >= charsToOpenOptions) {
      setIsListOpen(true);
    }
  };

  const handleClick = (option) => {
    setSelected({ ...option });
  };

  useEffect(() => {
    if (!selected) return;
    setInputValue(selected.label);
    handleSelect(selected);

    if (isListOpen) setIsListOpen(false);
  }, [selected]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <div ref={ref} className={styles.container}>
      <Input
        label={label}
        name={label.toLowerCase()}
        type="text"
        autoComplete={autoComplete || 'off'} // this is to avoid autocomplete on chrome
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      <div className={styles.iconWrapper}>
        {isListOpen ? (
          <OpenIcon className={styles.icon} />
        ) : (
          <ClosedIcon className={styles.icon} />
        )}
      </div>
      <div className={`${styles.optionList} ${isListOpen ? styles.open : ''}`}>
        {isListOpen &&
          filteredOptions.map((option, i) => {
            return (
              <div
                className={`${styles.option} ${
                  selected?.value === option.value ? styles.selected : ''
                }`}
                key={`${option.value}_${i}`}
                onClick={() => handleClick(option)}
              >
                {option.label}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const option = PropTypes.exact({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
});

SearchSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(option),
  defaultOption: option,
  handleSelect: PropTypes.func.isRequired,
  charsToOpenOptions: PropTypes.number,
  autoComplete: PropTypes.string,
};

export default SearchSelect;
