import React from 'react';
import PropTypes from 'prop-types';
import styles from './confirmation.module.scss';
import hiFivePng from 'assets/decorative-images/Hi-Five.png';
const Confirmation = ({ handleModalClose }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img
          src={hiFivePng}
          alt="success high-five"
          className={styles.image}
        />
        <h2 className={styles.title}>¡Tu solicitud se ha procesado con éxito!</h2>
        <p className={styles.message}>
          Pronto recibirás el dinero en tu cuenta y un correo con los detalles de tu
          solicitud.
        </p>
        <button className={styles.button} onClick={handleModalClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  loan: PropTypes.object,
  handleModalClose: PropTypes.func.isRequired,
};

export default Confirmation;
