// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M4OBi7QujBHXQe90wtT0{padding:2rem;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Onboarding/components/BiometricsFingerprint/biometrics-fingerprint.module.scss","webpack://./src/styles/variables/_space.scss"],"names":[],"mappings":"AAEA,sBAEE,YCCQ,CDKR,UAAA","sourcesContent":["@import 'styles/variables';\n\n.content {\n  // flex-grow: 1;\n  padding: $space-4;\n  // display: flex;\n  // flex-direction: column;\n  // align-items: center;\n  // justify-content: center;\n  // text-align: center;\n  width: 100%;\n  // background-color: red;\n}\n\n// .image {\n//   max-width: 400px;\n//   height: auto;\n \n//   margin: 0 auto;\n// }\n\n// .title {\n//   font-size: $font-size-xl;\n//   font-weight: $font-weight-bold;\n//   margin-top: $space-3;\n//   margin-bottom: $space-1;\n// }\n\n\n// .circular-frame {\n//   width: 250px;\n//   height: 250px;\n//   margin: 20px auto;\n//   border-radius: 50%;\n//   overflow: hidden;\n// }\n\n// .circular-frame img,\n// .circular-frame video {\n//   width: 100%;\n//   height: 100%;\n//   object-fit: cover;\n// }\n\n// .rounded-circle {\n//   border-radius: 50%;\n// }\n\n","$space-unit: 1rem;\n\n$space-1: 0.5 * $space-unit;\n$space-2: 1 * $space-unit;\n$space-3: 1.5 * $space-unit;\n$space-4: 2 * $space-unit;\n$space-5: 2.5 * $space-unit;\n$space-6: 3 * $space-unit;\n$space-7: 3.5 * $space-unit;\n$space-8: 4 * $space-unit;\n$space-9: 4.5 * $space-unit;\n$space-10: 5 * $space-unit;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `M4OBi7QujBHXQe90wtT0`
};
export default ___CSS_LOADER_EXPORT___;
