import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { getTyC } from 'api/termUser';
const useTerms = (initialLoadingState = false) => {
  const [isLoading, setIsLoading] = useState(initialLoadingState);
  
  const existsTermsConditionsToAccept = async (requiresAcceptanceTerms, error) => {
    setIsLoading(true);
    try {
      const tyC = await getTyC();
      if (!tyC.next)
        toast.error(tyC.message);
      else {
        if (tyC.requiresAcceptanceTerms) requiresAcceptanceTerms(true);
        else requiresAcceptanceTerms(false);
      }
    } catch (err) {
      toast.error(error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    existsTermsConditionsToAccept,
    isLoading
  };
};

export default useTerms;
