import React from 'react';
import PropTypes from 'prop-types';
import styles from './cta.module.scss';

const CallToAction = ({ type, disabled, form, isLoading, onClick, text, size }) => (
  <button
    form={form}
    type={type}
    className={`${styles.button} ${styles[size] || ''}`}
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    {isLoading ? <div className={styles.loader}></div> : text}
  </button>
);

CallToAction.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default CallToAction;
