import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Loading from 'components/Loading';
import { getUser } from 'api/user';
import useUser from 'hooks/useUser';
import useIsMounted from 'hooks/useIsMounted';
import routes from 'routes/routeList';
import { validateSelectedCompanyWithDemographic } from 'api/onboarding';
const PrivateRoute = ({ children }) => {
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState(true);
  const { user, setCurrentUser } = useUser();

  const fetchUser = async () => {
    const start = Date.now();
    const minLoadTime = 1000; // 1 second

    try {
      const userData = await getUser();
      const validateSelectedCompany = userData.isPendingRegistration ? await validateSelectedCompanyWithDemographic() : null;
      setCurrentUser({ ...userData, validateSelectedCompany });
    } catch (error) {
      if (error.response && error.response.status !== 401) {
        toast.error(error.response.data);
      }
    } finally {
      const end = Date.now();
      const loadTime = end - start;
      const remainingTime = minLoadTime - loadTime;

      if (remainingTime > 0) {
        await new Promise((resolve) => setTimeout(resolve, remainingTime));
      }

      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (isLoading) return <Loading />;
  if (user) return children;
  return <Navigate to={routes.login} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
