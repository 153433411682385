export default {
  apiUrl: process.env.API_URL,
  landingPageUrl: process.env.LANDING_URL,
  registrationUrl: `${process.env.LANDING_URL}/registrate`,
  termsUrl: `${process.env.LANDING_URL}/terminos`,
  helpLink: `https://wa.me/593939038128?text=¡Hola Misú! Necesito ayuda.`,
  onboardingLink: process.env.ONBOARDING_URL,
  // onboardingLink: 'https://onboarding.misu.ec?start=true',
  minPasswordLength: 8,
  userStatuses: {
    notRequested: 'inactivo',
    pending: 'pendiente',
    approved: 'aprobado',
    rejected: 'denegado',
    disabled: 'inhabilitado'
  },
  loanStatuses: {
    incomplete: 'incompleto',
    open: 'solicitado',
    granted: 'depositado',
    denied: 'denegado',
    paid: 'pagado',
    partiallyPaid: 'parcialmente_pagado',
    overdue: 'vencido',
    cancelled: 'cancelado',
    uncollectible: 'incobrable'
  },
  eclipsoftId4faceEnv: process.env.ECLIPSOFT_ID4FACE_ENV,
  eclipsoftId4faceUrl: process.env.ECLIPSOFT_ID4FACE_URL,
  eclipsoftId4faceIntegrity: process.env.ECLIPSOFT_ID4FACE_INTEGRITY,
  eclipsoftId4faceUrlCallbackUrl: process.env.ECLIPSOFT_ID4FACE_URL_CALLBACK,
};
