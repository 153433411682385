import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from 'routes/routeList';
import { RiWhatsappLine as HelpIcon } from '@react-icons/all-files/ri/RiWhatsappLine';
import ProfilePreview from './components/ProfilePreview';
import LeanNavbar from './components/LeanNavbar';
import LoanFormModal from '../../views/LoanFormModal';
import config from 'config';
import useStore from 'store';
import helpers from './helpers';
import { getUserInitials } from 'utils/strings';
import logo from 'assets/logo.svg';
import styles from './navbar.module.scss';
import ctaStyles from '../CallToAction/cta.module.scss';
import TermsModal from 'components/Terms';
import useTerms from 'hooks/useTerms';

const Navbar = () => {
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const { user, setIsFormOpen } = useStore((state) => state);
  const [tyCModalOpen, setTyCModalOpen] = useState(false);
  const { existsTermsConditionsToAccept, isLoading } = useTerms();
  const { pathname } = useLocation();

  const initials = getUserInitials(user);
  const isApproved = helpers.isUserApproved(user);

  if (helpers.shouldHideNavbar(pathname)) return null;
  if (helpers.shouldRenderLeanNavbar(pathname)) return <LeanNavbar />;

  const handleExistsTermsConditionsToAccept = async () => {
    existsTermsConditionsToAccept(
      (requiresAcceptanceTerms) => {
        if (requiresAcceptanceTerms) setTyCModalOpen(true);
        else setIsFormOpen(true);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <nav className={styles.container}>
        <Link to={routes.home} className={styles.logo}>
          <img src={logo} alt="logo" />
        </Link>
        <div className={styles.rightSection}>
          {
            !isApproved && (<a href={config.helpLink} rel="noreferrer" target="_blank" style={{ marginRight: '1rem' }}>
              <button className={styles.helpButton}>
                <HelpIcon />
                <span>Ayuda</span>
              </button>
            </a>
            )
          }

          {isApproved && (
            <button
              className={styles.newRequestButton}
              onClick={() => handleExistsTermsConditionsToAccept()}
              disabled={isLoading}
            >
              {isLoading ? <div className={ctaStyles.loader}></div> : 'Nuevo anticipo'}
            </button>
          )}
          <div className={styles.profileButtonContainer}>
            <span
              className={styles.profileButton}
              onClick={() => setIsProfilePreviewOpen(true)}
            >
              {initials}
            </span>
            <ProfilePreview
              isOpen={isProfilePreviewOpen}
              setIsOpen={setIsProfilePreviewOpen}
            />
          </div>
        </div>
      </nav>
      <LoanFormModal />
      <TermsModal
        type={'TYC'}
        isOpen={tyCModalOpen}
        setIsOpen={setTyCModalOpen}
        callback={() => setIsFormOpen(true)}
      />
    </div>
  );
};

export default Navbar;
