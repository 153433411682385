import axios from '../lib/axios';
export const findNextSteps = () => {
  return axios.get('/onboarding/find-next-steps');
};
export const startDemographic = () => {
  return axios.get('/onboarding/start-demographic');
};
export const finishDemographic = (data) => {
  return axios.post('/onboarding/finish-demographic', data);
};
export const finishProcess = () => {
  return axios.post('/onboarding/finish-process');
};

export const passwordChange = (data) => {
  return axios.put('/onboarding/password-change', data);
};

export const biometrics = (data) => {
  return axios.post('/onboarding/biometrics', data);
};

export const checkIdentityNumber = (data) => {
  return axios.post('/onboarding/biometrics/check-identity-number', data);
};

export const getTokenBiometrics = (data) => {
  return axios.get('/onboarding/biometrics/token');
};
export const validateSelectedCompanyWithDemographic = () => {
  return axios.get('/onboarding/validate-selected-company-with-demographic');
};
