import React, { useEffect, useState } from 'react';
import styles from './tyc-no-access.module.scss';
import useTerms from 'hooks/useTerms';
import TermsModal from 'components/Terms';
import bookPng from 'assets/decorative-images/Book.png';

const TyCNoAccess = () => {
  const [tyCModalOpen, setTyCModalOpen] = useState(false);

  const { existsTermsConditionsToAccept } = useTerms();

  useEffect(() => {
    existsTermsConditionsToAccept((requiresAcceptanceTerms) => {
      if (requiresAcceptanceTerms) setTyCModalOpen(true);
      (error) => toast.error(error);
    });
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h2 className={styles.title}>Estimado/a cliente,</h2>
          <p className={styles.subtitle}>
            Le informamos que para hacer uso de nuestra plataforma es necesario aceptar
            los&nbsp;
            <a
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={() => setTyCModalOpen(true)}
            >
              <strong>términos y condiciones</strong>
            </a>
            . Sin dicha aceptación, no será posible acceder a nuestros servicios.
            Agradecemos su comprensión.
          </p>
          <img
            className={styles.image}
            src={bookPng}
            alt=""
          />
        </div>
      </div>
      <TermsModal
        type={'TYC'}
        isOpen={tyCModalOpen}
        setIsOpen={setTyCModalOpen}
        callback={handleReload}
      />
    </>
  );
};

export default TyCNoAccess;
